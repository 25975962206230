import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Column, Layout, Row, Song, Text } from '../components'
import { TEXTS } from '../constants'

import styles from './75.module.css'

export default () => {
    const { text, victoryDay, order8may, order9may, stalin, levitan, firework } = useStaticQuery(graphql`
      {
        text: contentfulText(tid: {eq: "75th-anniversary"}) {
          title
          content {
            json
          }
        },
        victoryDay: contentfulSong(title: {eq: "День победы"}) {
          title
          year
          singers
          musicAuthor
          lyricsAuthor
          fileMp3 {
            localFile {
              publicURL
            }
          }
          fileOgg {
            localFile {
              publicURL
            }
          }
            isLyricsAndMusicMerged
            isShowSingersLabel
        },
        order8may: contentfulSong(title: {eq: "Приказ Верховного Главнокомандующего от 8 мая 1945 года"}) {
          title
          fileMp3 {
            localFile {
              publicURL
            }
          }
          fileOgg {
            localFile {
              publicURL
            }
          }
        },
        order9may: contentfulSong(title: {eq: "Приказ Верховного Главнокомандующего от 9 мая 1945 года"}) {
          title
          fileMp3 {
            localFile {
              publicURL
            }
          }
          fileOgg {
            localFile {
              publicURL
            }
          }
        },
        stalin: contentfulAsset(title: {eq: "Сталин"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        firework: contentfulAsset(title: {eq: "Салют Победы"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        levitan: contentfulAsset(title: {eq: "Левитан"}) {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
      }`)
    return (
        <Layout pageTitle={text.title}>
            <Row>
                <Column width={62.9}>
                    <Img fluid={levitan.localFile.childImageSharp.fluid} alt={TEXTS.levitan} className={styles.image}/>
                    <div className={styles.audio}>
                        <Song {...order8may} single/>
                        <Song {...order9may} single/>
                        <Song {...victoryDay} single/>
                    </div>
                </Column>
                <Column width={37.1}>
                    <Img fluid={stalin.localFile.childImageSharp.fluid} alt={TEXTS.stalin} className={styles.image}/>
                    <Text content={text.content.json}/>
                </Column>
            </Row>
            <Row>
                <Column width={100}>
                    <Img fluid={firework.localFile.childImageSharp.fluid} alt={TEXTS.firework}/>
                </Column>
            </Row>
        </Layout>
    )
}
